<template>
  <v-app-bar app fixed color="white" flat>
    <MenuCollapsed />

    <v-toolbar-title class="subtitle-2 font-weight-regular subtitle-color">
      {{ isPresensi ? "PRESENSI" : "SIMPEG" }} DITJEN PAUD DIKDAS DIKMEN
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-title
      class="subtitle-2 font-weight-regular subtitle-color mr-4"
      >{{ currentDate | date }}</v-toolbar-title
    >

    <!-- <v-toolbar-title class="subtitle-2 font-weight-regular subtitle-color mr-4"
      >TOTAL PEGAWAI: 3774</v-toolbar-title
    > -->

    <!-- <v-divider vertical class="mx-2"></v-divider>

    <v-btn icon @click="getToPage('chat')">
      <v-badge color="error" dot overlap>
        <v-icon>email</v-icon>
      </v-badge>
    </v-btn>

    <v-menu id="custom-menu" transition="slide-y-transition" bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-badge color="error" dot overlap>
            <v-icon>notifications</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card style="min-width: 400px" class="pa-2 rounded-lg speech-bubble">
        <v-subheader>Notifikasi</v-subheader>
        <v-divider class="my-0 mx-3" />
        <v-list>
          <template v-if="notifications.length > 0">
            <div v-for="(item, index) in notifications" :key="index">
              <v-list-item
                @click="goToDetail(item.type)"
                style="display: block"
                class="py-2 mx-3"
                :style="
                  item.status == 'UNREAD' ? 'background-color: #E1F5FE' : ''
                "
              >
                <p class="caption font-weight-bold ma-0">
                  {{ item.subject }}
                </p>
                <p class="caption font-weight-regular ma-0 green--text">
                  {{ item.description | str_limit(50) }}
                  <span class="floating-right subtitle-color">{{
                    item.notification_date
                  }}</span>
                </p>
                <v-btn
                  v-if="item.type == 'OTHER'"
                  x-small
                  color="#FBB005"
                  class="text-capitalize caption px-4 my-2"
                  >Lihat Dokumen</v-btn
                >
              </v-list-item>
              <v-divider class="mx-3" v-if="index < notifications.length - 1" />
            </div>
          </template>
          <v-list-item v-else style="display: block" class="py-2 mx-3">
            <p class="caption text-center ma-0 text-subtitle">
              Tidak Ada Notifikasi
            </p>
          </v-list-item>
        </v-list>
        <v-card-actions class="justify-center" v-if="notifications.length > 1">
          <v-btn
            @click="getToPage('notification')"
            text
            block
            color="primary"
            class="text-capitalize caption"
            >Lihat Semua Notifikasi</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu> -->

    <v-divider vertical class="mx-2"></v-divider>

    <v-menu transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="profile.photo" contain height="80" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ profile.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                profile.role | snakeToTitle
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item @click="goToProfile">
            <v-list-item-title>LIHAT PROFIL</v-list-item-title>
            <v-list-item-icon>
              <v-icon small right>person</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-title>KELUAR</v-list-item-title>
            <v-list-item-icon>
              <v-icon small right>exit_to_app</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <LogoutDialog
      :visible="dialogLogoutVisible"
      :onSubmit="_submitDialog"
      :onCancel="_cancelDialog"
      :onLoading="authLoading"
      title="Apakah anda yakin ingin keluar?"
    >
    </LogoutDialog>
  </v-app-bar>
</template>

<script>
import NotificationService from "@/services/resources/notification.service";

const MenuCollapsed = () => import("@/components/Menu/Collapsed");
const LogoutDialog = () => import("@/components/Dialog/Logout");
import { mapActions, mapGetters } from "vuex";
import { NOTIFICATION_TYPE } from "@/common/constant";
import { mapSummaryRootField, mapAuthRootField } from "@/store/helpers";

export default {
  components: {
    MenuCollapsed,
    LogoutDialog
  },
  name: "Sidebar",
  data() {
    return {
      loading: false,
      dialogLogoutVisible: false,
      currentDate: this.$moment(),
      notifications: [],
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" }
      ]
    };
  },
  methods: {
    goToProfile() {
      this.$router.push({ name: "profile" });
    },
    ...mapActions({
      toggleDrawer: "DRAWER_COLLAPSED"
    }),
    goToDetail(type) {
      this.getToPage(NOTIFICATION_TYPE[type]);
    },
    logout() {
      this.dialogLogoutVisible = true;
    },
    _submitDialog() {
      this.$store.commit("auth/purgeAuth");
      this.$router.push({ name: "login" });
    },
    _cancelDialog() {
      this.dialogLogoutVisible = false;
    },
    // Service
    async getListNotification() {
      try {
        this.loading = true;
        await NotificationService.getList({
          orderBy: ["id", "desc"],
          filter: {
            search: ""
          },
          pageCurrent: 1,
          dataPerPage: 5
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.notifications = data.list;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  computed: {
    ...mapGetters(["authLoading"]),
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    }),
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    ...mapAuthRootField({
      profile: "profile"
    })
  },
  mounted() {
    this.getListNotification();
  }
};
</script>
<style>
#custom-menu.v-menu__content {
  margin-top: 15px !important;
}
.speech-bubble {
  position: relative;
  background: #00aabb;
  border-radius: 0.4em;
}
.speech-bubble:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: #00aabb;
  border-top: 0;
  border-left: 0;
  margin-left: -10px;
  margin-top: -20px;
}
</style>
